<template>
  <ek-panel title="数字化资产">
    <div class="box">
      <div class="left">
        <div class="text" v-for="(item, index) in list" :key="index">
          <div class="t">{{ item.title }}</div>
          <div class="v-box">
            <count-to
              separator=""
              :startVal="0"
              :decimals="item.decimals"
              :endVal="item.num || 0"
              :duration="2000"
              class="v"
            /><span class="unit">{{ item.unit }}</span>
          </div>
        </div>
      </div>
      <div class="right">
        <img class="bg" src="/images/components/iw/r2.png" alt="" />
        <ek-chart
          :options="options"
          :animation="true"
          :animationData="options.series[0].data"
        />
      </div>
    </div>
  </ek-panel>
</template>

<script>
import { fetchData } from '@/api'

export default {
  data() {
    return {
      list: [
        { title: '商户总数', num: 0, decimals: 0, unit: '个' },
        { title: '空置商铺', num: 0, decimals: 0, unit: '个' },
        { title: '空置率', num: 0, decimals: 2, unit: '%' }
      ],
      options: {
        color: [
          '#1DADFF',
          '#24DCF7',
          '#00C800',
          '#12B796',
          '#D95040',
          '#275FC6',
          '#FFC000'
        ],
        title: {
          text: `资产管理`,
          top: '45%',
          left: '38%',
          textAlign: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 20,
            fontWeight: '400'
          }
        },
        tooltip: {
          trigger: 'item',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let el = `<div class="tooltip-box column">
                      <span class="b-t">${params.seriesName}</span>
                      <div class="text" style="font-size:18px;margin-top:5px;">
                      <div class="right">
                          <span>${params.marker}</span>
                          <span class="t">${params.name}</span>
                          <span class="v">${params.value}家</span>
                        </div>
                      </div>
                    </div>`
            return el
          }
        },
        legend: {
          orient: 'vertical',
          top: 'center',
          right: '5%',
          icon: 'rect',
          itemWidth: 18,
          itemHeight: 11,
          itemGap: 15,
          type: 'scroll',
          pageTextStyle: {
            color: '#fff'
          },
          textStyle: {
            color: '#fff',
            fontSize: 18,
            padding: [0, 10]
          }
        },
        series: [
          {
            name: '资产管理',
            type: 'pie',
            center: ['39%', '50%'],
            radius: ['38%', '64%'],
            label: {
              color: '#fff',
              formatter: `{b|{b}}\n{d|{d}%}`,
              fontSize: 18,
              rich: {
                d: {
                  color: '#2AFFEB',
                  fontSize: 15,
                  lineHeight: 30
                }
              }
            },
            itemStyle: {
              borderColor: '#050A0F',
              borderWidth: 2
            },
            selectedMode: 'single',
            data: []
          }
        ]
      }
    }
  },
  components: {},
  mounted() {
    this.fetchListData()
    this.fetchChartData()
  },
  methods: {
    async fetchListData() {
      const { resultCode, resultData } = await fetchData('asset_stat')
      if (resultCode === '200' && resultData) {
        let list = resultData.content
        this.list[0].num = list[0].total
        this.list[1].num = list[0].vacancy_num
        this.list[2].num = list[0].tongbi
      }
    },
    async fetchChartData() {
      const { resultCode, resultData } = await fetchData('asset')
      if (resultCode === '200' && resultData) {
        let list = resultData.content
        let tempList = []
        list.map(obj => {
          tempList.push({
            value: obj.value,
            name: obj.title
          })
        })
        this.options.series[0].data = tempList
      }
    }
  }
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: mFont;
  src: url('../../../styles/font/LED.ttf');
}

.box {
  height: 290px;
  @include flex();
  .left {
    @include flex(column);
    justify-content: space-between;
    padding: 22px 15px;
  }
  .right {
    flex: 1;
    position: relative;
    @include flex();
    align-items: center;
  }
  .text {
    @include flex();
    align-items: center;
    background: rgba($color: #ef9c00, $alpha: 0.2);
    padding: 0 16px;
    width: 230px;
    height: 68px;
    .t {
      width: 93px;
      font-size: 22px;
      margin-right: 16px;
    }
    .v-box {
      @include flex();
      align-items: baseline;
      color: #ef9c00;
      .v {
        font: 50px mFont;
        line-height: 44px;
        margin-bottom: 10px;
      }
      .unit {
        font-size: 24px;
      }
    }
  }
  .bg {
    position: absolute;
    left: 19.2%;
    width: 248px;
    height: 248px;
  }
}
</style>
