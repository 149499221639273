<template>
  <ek-panel title="数字化招商">
    <div class="box">
      <div class="item" v-for="(item, index) in list" :key="index">
        <span class="title">{{ item.title + `(${item.unit})` }}</span>
        <div class="bottom-box">
          <div class="left">
            <count-to
              :decimals="item.unit == '亿元' ? 2 : 0"
              :startVal="0"
              :endVal="item.num || 0"
              :duration="2000"
              class="num"
            />
            <!-- <span class="num"></span> -->
            <div class="text">
              <span>同比</span>
              <img
                :src="
                  `/images/components/iw/${
                    item.tendency == '1' ? 'inc' : 'dec'
                  }.png`
                "
                alt=""
              />
              <span class="v">{{ item.percent }}%</span>
            </div>
          </div>
          <div class="right">
            <div class="chart-box">
              <ek-chart :options="item.options" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ek-panel>
</template>

<script>
import * as echarts from 'echarts'
import { fetchData } from '@/api'

export default {
  data() {
    return {
      list: [
        {
          title: '存续商家总数',
          unit: '家',
          num: 0,
          percent: 0,
          options: {
            grid: {
              top: '5%',
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              axisLabel: {
                show: false
              },
              axisLine: {
                show: false
              },
              // 不显示刻度线
              axisTick: {
                show: false
              },
              splitLine: {
                // 网格线为虚线
                show: false
              }
            },
            yAxis: {
              type: 'value',
              splitLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              axisLabel: {
                show: false
              }
            },
            series: [
              {
                type: 'bar',
                barWidth: '50%',
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#EF9C00'
                    },
                    {
                      offset: 1,
                      color: '#142D3F'
                    }
                  ])
                },
                data: [50, 55, 65, 70, 90, 100]
              }
            ]
          }
        },
        {
          title: '出租率',
          unit: '%',
          num: 83,
          percent: 12,
          tendency: '1',
          options: {
            grid: {
              top: '5%',
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              axisLabel: {
                show: false
              },
              axisLine: {
                show: false
              },
              // 不显示刻度线
              axisTick: {
                show: false
              },
              splitLine: {
                // 网格线为虚线
                show: false
              }
            },
            yAxis: {
              type: 'value',
              splitLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              axisLabel: {
                show: false
              }
            },
            series: [
              {
                type: 'bar',
                barWidth: '50%',
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#EF9C00'
                    },
                    {
                      offset: 1,
                      color: '#142D3F'
                    }
                  ])
                },
                data: [50, 55, 80, 70, 90, 100]
              }
            ]
          }
        }
      ]
    }
  },
  components: {},
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const { resultCode, resultData } = await fetchData('merchants')
      if (resultCode === '200' && resultData) {
        let list = resultData.content
        list.map(obj => {
          if (obj.title.indexOf(this.list[0].title) != -1) {
            this.list[0].num = obj.decimals_value
            this.list[0].tendency = obj.tendency
            this.list[0].percent = obj.tongbi
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: 219px - 60px;
  padding: 30px;
  @include flex();
  justify-content: space-between;
  .item {
    @include flex(column);
    justify-content: space-between;
    // flex: 1;
    // height: 100% !important;
    .title {
      font-size: 26px;
    }
    .bottom-box {
      @include flex();
      // align-items: baseline;
      // justify-content: space-between;
      // flex: 1;
      .left {
        @include flex(column);
        // flex: 1;
      }
      .right {
        // flex: 1;
        @include flex(column);
        align-items: center;
        justify-content: flex-end;
        margin-left: 40px;
        .chart-box {
          width: 180px;
          height: 100px;
        }
      }
      .right:first-child {
        margin-right: 20px;
      }
      .num {
        font-size: 46px;
        font-weight: bold;
        color: #ef9c00;
        margin-top: 22px;
      }
      .text {
        @include flex();
        align-items: center;
        font-size: 26px;
        margin-top: 22px;
        white-space: nowrap;
        img {
          margin: 0 5px;
        }
      }
    }
  }
}
</style>
