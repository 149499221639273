<template>
  <div class="container">
    <r1></r1>
    <r2></r2>
    <r3></r3>
  </div>
</template>

<script>
import r1 from './components/r1'
import r2 from './components/r2'
import r3 from './components/r3'

export default {
  components: {
    r1,
    r2,
    r3
  }
}
</script>

<style scoped lang="scss">
.container {
  @include flex(column);
  justify-content: space-between;
  align-content: space-between;
}
</style>
