<template>
  <ek-panel title="数字化物管">
    <div class="box">
      <div class="t-box">
        <div
          class="text"
          :style="{ width: `${100 / items.length}%` }"
          v-for="(item, index) in items"
          :key="index"
        >
          <span class="v">
            <count-to
              separator=""
              :startVal="0"
              :endVal="item.num || 0"
              :duration="2000"
            /><span class="unit">{{ item.unit }}</span>
          </span>
          <span class="t">{{ item.title }}</span>
        </div>
      </div>
      <div class="i-box">
        <div class="t-header">
          <span>序号</span>
          <span>工单内容</span>
          <span>上报人</span>
          <span>上报时间</span>
        </div>
        <div class="t-list">
          <swiper ref="orderSwiper" :options="swiperOptions">
            <swiper-slide v-for="(array, index) in list" :key="index">
              <div
                class="item"
                v-for="(item, index) in array"
                :key="index"
                @mouseenter="onEnterClick"
                @mouseleave="onLeaveClick"
              >
                <span>{{ item.number }}</span>
                <span>{{ item.content }}</span>
                <span>{{ item.reporter }}</span>
                <span>{{ item.time }}</span>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </ek-panel>
</template>

<script>
import { fetchData } from '@/api'

export default {
  data() {
    return {
      items: [
        { title: '工单总数', num: 0, unit: '单' },
        { title: '待办工单', num: 0, unit: '单' },
        { title: '完结工单', num: 0, unit: '单' },
        { title: '完成率', num: 0, unit: '%' }
      ],
      list: [],
      swiperOptions: {
        direction: 'vertical',
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
        speed: 900,
        loop: false
      }
    }
  },
  components: {},
  computed: {
    swiper() {
      return this.$refs.orderSwiper.$swiper
    }
  },
  mounted() {
    this.fetchTotalData()
    this.fetchListData()
  },
  methods: {
    async fetchTotalData() {
      const { resultCode, resultData } = await fetchData('work_order_stat')
      if (resultCode === '200' && resultData) {
        let list = resultData.content
        this.items[0].num = list[0].total
        this.items[1].num = list[0].todo_num
        this.items[2].num = list[0].complete_num
        this.items[3].num = list[0].tongbi
      }
    },
    async fetchListData() {
      const { resultCode, resultData } = await fetchData('work_order')
      if (resultCode === '200' && resultData) {
        let list = resultData.content
        let tempList = list.map((obj, i) => {
          obj.number = i + 1
          obj.content = obj.desc
          obj.reporter = obj.store_contact
          // obj.time = this.dayjs(obj.create_date).format('YYYY/MM/DD')
          obj.time = this.dayjs().format('YYYY/MM/DD')

          return obj
        })
        this.list = this.groupList(tempList, 3)
      }
    },
    onEnterClick() {
      this.swiper.autoplay.stop()
    },
    onLeaveClick() {
      this.swiper.autoplay.start()
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: 361px - 24px;
  padding: 10px 30px 14px 30px;
  @include flex(column);
  .t-box {
    @include flex();
    align-items: center;
    // justify-content: center;
    background: #202738;
    margin-bottom: 10px;
    height: 86px;
    .text {
      position: relative;
      @include flex(column);
      align-items: center;
      justify-content: center;
      .v {
        font-size: 30px;
        font-weight: bold;
      }
      .unit {
        font-size: 18px;
      }
      .t {
        color: #0af7bc;
        margin-top: 10px;
      }
    }

    .text:nth-child(-n + 3)::after {
      content: '';
      position: absolute;
      right: 0;
      width: 1px;
      height: 40px;
      background-color: #4a546b;
    }
  }
  .i-box {
    @include flex(column);
    width: 100%;
    .t-header {
      @include flex();
      align-items: center;
      height: 60px;
      background: #202738;
      span {
        font-size: 20px;
        text-align: center;
      }
    }

    .t-header > span:nth-child(1),
    .swiper-slide > .item > span:nth-child(1) {
      flex: 1;
    }
    .t-header > span:nth-child(2),
    .swiper-slide > .item > span:nth-child(2) {
      flex: 7;
    }
    .t-header > span:nth-child(3),
    .swiper-slide > .item > span:nth-child(3) {
      flex: 1;
    }
    .t-header > span:nth-child(4),
    .swiper-slide > .item > span:nth-child(4) {
      flex: 2;
    }

    .t-list {
      @include flex(column);
      flex: 1;
      .swiper-container {
        height: 180px;
        width: 100%;
      }
      .item {
        width: 100%;
        @include flex();
        align-items: center;
        height: 60px;
        background: rgba($color: #202738, $alpha: 0.8);
        span {
          text-align: center;
          font-size: 20px;
        }
      }
    }

    .swiper-slide > .item:nth-child(odd) {
      background: rgba($color: #202738, $alpha: 0.5);
    }
  }
}
</style>
