<template>
  <ek-panel title="数字化服务">
    <div class="box">
      <ek-chart
        :options="options"
        :animation="true"
        :animationData="options.series[0].data"
      />
    </div>
  </ek-panel>
</template>

<script>
import { fetchData } from '@/api'

export default {
  data() {
    return {
      options: {
        color: [
          '#1DADFF',
          '#00C800',
          '#24DCF7',
          '#275FC6',
          '#12B796',
          '#D95040',
          '#FFC000',
          '#FF7800',
          '#43FFD9'
        ],
        title: {
          text: `9项\n\n商家服务`,
          top: '39%',
          left: '33.5%',
          textAlign: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 20,
            fontWeight: '400'
          }
        },
        tooltip: {
          trigger: 'item',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let el = `<div class="tooltip-box column">
                      <span class="b-t">${params.seriesName}</span>
                      <div class="text" style="font-size:18px;margin-top:5px;">
                      <div class="right">
                          <span>${params.marker}</span>
                          <span class="t">${params.name}</span>
                          <span class="v">${params.value}家</span>
                        </div>
                      </div>
                    </div>`
            return el
          }
        },
        legend: {
          orient: 'vertical',
          top: 'center',
          right: '8%',
          icon: 'rect',
          itemWidth: 18,
          itemHeight: 11,
          itemGap: 15,
          textStyle: {
            color: '#fff',
            fontSize: 20,
            padding: [0, 10]
          }
        },
        series: [
          {
            name: '9项商家服务',
            type: 'pie',
            center: ['34%', '50%'],
            radius: ['45%', '70%'],
            label: {
              color: '#fff',
              formatter: `{b|{b}} {d|{d}%}`,
              fontSize: 18,
              rich: {
                d: {
                  color: '#2AFFEB',
                  fontSize: 18
                }
              }
            },
            itemStyle: {
              borderColor: '#050A0F',
              borderWidth: 3
            },
            selectedMode: 'single',
            data: []
          }
        ]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const { resultCode, resultData } = await fetchData('store_services')
      if (resultCode === '200' && resultData) {
        const list = resultData.content
        let seriesData = []
        list.map(item => {
          seriesData.push({
            name: item.title,
            value: item.value
          })
        })
        this.options.series[0].data = seriesData
      }
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: 347px;
  @include flex();
}
</style>
