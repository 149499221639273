<template>
  <div class="iw-l1">
    <ek-panel title="数字化管理">
      <div class="box">
        <img src="/images/components/iw/l1.png" alt="" />
      </div>
    </ek-panel>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  mounted() {},
  methods: {
    async fetchData() {
    
    }
  }
}
</script>

<style scoped lang="scss">
.iw-l1 {
}
.box {
  padding: 10px;
  height: 583px - 20px;
  @include flex(row);
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
